import React, { useEffect, useState, useLayoutEffect } from "react";
import { Helmet } from "react-helmet-async";
import FBPicture from 'pages/leadbest-official-website/assets/images/meta-image.jpg'

const HelmetCSS = (props: any) => {
    return (
        <Helmet>
            <link rel="stylesheet" media="screen" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" media="screen" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <meta property="og:url" content="https://www.leadbestgroup.com/" />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content={`${window.Config.FB_APP_ID}`} />
            <meta property="og:image" content={`https://www.leadbestgroup.com${FBPicture}`} />
        </Helmet>
    );
};


export default HelmetCSS